



























import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Tool from '@/shared/modules/tool/models/tool.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import permissions from '@/shared/helpers/permissions.helper'
import ToolHistory from '@/company/views/tools/ToolHistory.vue'
import ToolTypeFilesEditDialog from '@/company/views/tools/ToolTypeFilesEditDialog.vue'
import { getBase64 } from '@/shared/helpers/get-base64.helper'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'
import FormGroup from '@/shared/classes/form/group'
import ListToolPhotos from '@/company/components/tools/ListToolPhotos.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import ToolService from '@/shared/modules/tool/tool.service'
import can from '@/shared/helpers/can.helper'

@Component({
  components: {PageHeader, Form, ToolHistory },
  methods: { __, can }
})
export default class ToolsEdit extends Vue {
  form: FormBase = null!
  permissions = permissions
  tool: Tool|null = null

  static GROUP_MAIN = 'main'
  static GROUP_ATTACHMENTS = 'attachments'

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/tools`)
      .setUuid(this.$router.currentRoute.params.uuid)
      .setModel(Tool)
      .setMethod(HttpMethod.PUT)
      .setOnSetEntry((tool: any) => this.tool = tool)
      .setInjectValues({
        company_uuid: this.company.uuid
      })
      .setGroups([
        new FormGroup()
          .setKey(ToolsEdit.GROUP_MAIN),
        new FormGroup()
          .setKey(ToolsEdit.GROUP_ATTACHMENTS)
          .setComponent(ListToolPhotos),
      ])
      .setFields([
        new Field()
          .setType(FieldTypes.number)
          .setKey('internal_no')
          .setTitle(__('company.views.tools.form.details.internal_no'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .isRequired(),
        new Field()
          .setKey('name')
          .setTitle(__('company.views.tools.form.details.name'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .isRequired(),
        new SearchableField()
          .setKey('category_uuid')
          .setTitle(__('company.views.tools.form.details.category'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .loadItems({
            endpoint: `/company/${ this.company.slug }/tool-categories`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.nulled,
                name: 'deactivated_at',
                value: true
              }
            ]
          })
          .isRequired(),
        new SearchableField()
          .setKey('location_uuid')
          .setTitle(__('company.views.tools.form.details.location'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .loadItems({
            endpoint: `/company/${ this.company.slug }/tool-locations`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.nulled,
                name: 'deactivated_at',
                value: true
              }
            ]
          })
          .setDisabled(true)
          .isRequired(),
        new SearchableField()
          .setKey('retrieved_by_user_uuid')
          .setTitle(__('company.views.tools.form.details.retrieved_by'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .loadItems({
            endpoint: `/company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.equals,
                name: 'role.level',
                value: RoleLevel.employee,
              }
            ]
          })
          .setDisabled(true)
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('retrieved_date')
          .setTitle(__('company.views.tools.form.details.retrieved_date'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setDisabled(true)
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('purchased_date')
          .setTitle(__('company.views.tools.form.details.purchased_date'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('purchased_place')
          .setTitle(__('company.views.tools.form.details.purchased_place'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('manufacturer')
          .setTitle(__('company.views.tools.form.details.manufacturer'))
          .setGroup(ToolsEdit.GROUP_MAIN),
        new Field()
          .setType(FieldTypes.number)
          .setKey('price')
          .setTitle(__('company.views.tools.form.details.price'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_internal_price')
          .setTitle(__('company.views.tools.form.details.rental_internal_price'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_external_price')
          .setTitle(__('company.views.tools.form.details.rental_external_price'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('fixed_location')
          .setTitle(__('company.views.tools.form.details.fixed_location'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setVisibleIf((values) => this.user.hasDynamicPermissions([permissions.company.tools.store, permissions.company.tools.update]))
          .isRequired(),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('expected_finished')
          .setTitle(__('company.views.tools.form.details.expected_finished'))
          .setGroup(ToolsEdit.GROUP_MAIN)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('link')
          .setTitle(__('company.views.tools.form.details.link'))
          .setGroup(ToolsEdit.GROUP_MAIN),
        new Field()
          .setType(FieldTypes.textArea)
          .setKey('comment')
          .setTitle(__('company.views.tools.form.details.comment'))
          .setGroup(ToolsEdit.GROUP_MAIN),
        new Field()
          .setType(FieldTypes.custom)
          .setKey('photos')
          .setValue([])
          .setComponent(ToolTypeFilesEditDialog)
          .setGroup(ToolsEdit.GROUP_MAIN),
      ])
      .setSubmit({
        text: __('company.views.tools.edit.form.submit')
      })
      .setBeforeSubmit(this.submit)
      .setOnSuccess(this.onSuccess)
  }

  async submit() {
    if (_.get(this.form.data, 'photos')) {
      await Promise.all(this.form.data.photos.map(async (toolPhoto: any, toolPhotoIndex: number) => {
        const base64 = await getBase64(toolPhoto.file)
        _.set(this.form.data, `photos.${ toolPhotoIndex }.file_name`, toolPhoto.file.name)
        _.set(this.form.data, `photos.${ toolPhotoIndex }.file`, base64)
      }))
    }
  }

  moveTool(): void {
    this.$router.push({ name: CompanyRoutes.toolsMove, params: { tools: this.form.entry.uuid } })
  }

  discardTool(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.tools.edit.discard.title'),
        text: __('company.views.tools.edit.discard.text', { name: this.form.entry.name }),
        onYes: () => ToolService.discard(this.company.slug, this.form.entry.uuid)
          .then((tool: Tool) => {
            this.form.setEntry(tool)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.tools.edit.discard.success')
            })
          })
      }
    })
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.toolsIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.tools.edit.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
