






























import { Component, Prop, Vue } from 'vue-property-decorator'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'
import ArrayField from '@/shared/classes/form/fields/array-field'
import __ from '@/shared/helpers/__'
import { FieldTypes } from '@/shared/components/form/field-types'
import _ from 'lodash'
import Form from '@/shared/components/form/Form.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Attachment from '@/shared/modules/attachment/attachment.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import AddMultiplePhotosButton from '@/company/components/task-types/AddMultiplePhotosButton.vue'
import CompanyService from '@/shared/modules/company/company.service'

@Component({
  components: {
    Form,
    DataTable
  },
  methods: {
    __
  }
})
export default class TaskTypeFilesEditDialog extends Vue {
  @Prop() field!: Field
  @Prop() form!: FormBase
  @Prop() fullKey!: string
  dialog: boolean = false

  table: DataTableBase = new DataTableBase()
    .setModel(Attachment)
    .setHasFilter(false)
    .setUpdateUrl(false)
    .setPaginationNotVisible(true)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.name')),
      new DataTableHeader()
        .setKey('customProperties.description')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.description'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('customProperties.author.full_name')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.author')),
      new DataTableHeader()
        .setKey('createdAt')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.uploaded-date'))
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-eye')
        .setAction((attachment: Attachment) => {
          const tab: any = window.open(attachment.url, '_blank')
          tab.focus()
        })
        .setTitle(__('company.components.tasks.edit-task-attachments.show')),
      new DataTableAction()
        .setIcon('mdi-download')
        .setAction((attachment: Attachment) => {
          CompanyService.downloadAttachment(this.company, attachment)
            .then((response: any) => downloadFile(response, attachment.name))
        })
        .setTitle(__('company.components.tasks.edit-task-attachments.download')),
      new DeleteAction()
        .setAction((attachment: Attachment) => {
          CompanyService.deleteAttachment(this.company, attachment).then(() => {
            this.table && this.table.removeItem(attachment)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.components.tasks.edit-task-attachments.delete', { name: attachment.name }),
            })
          })
        })
    ])

  photosForm: FormBase = new FormBase()
    .setFields([
      new ArrayField()
        .setKey('photos')
        .setChildren([
          new Field()
            .setType(FieldTypes.file)
            .setKey('file')
            .setTitle(__('company.components.task-types.task-type-files-dialog.form.file'))
            .setValue([])
            .setMeta({
              type: 'image',
              accept: 'image/*',
            })
        ])
        .setAddButton(() => false)
        .setAdditionalActions([
          AddMultiplePhotosButton
        ])
    ])
    .setOnSuccess((data: any) => {
      _.set(this.form.data, this.fullKey, data.photos.filter((photo: any) => {
        if (photo.file) {
          if (photo.file.length !== 0) return photo
        }
      }))

      this.onClose()
    })

  created() {
    this.table.setData(_.get(this.form.data, this.fullKey, []))
    _.set(this.form.data, this.fullKey, [])
  }

  openDialog() {
    this.dialog = true
  }

  onClose() {
    this.dialog = false
  }

  get badgeCount(): number {
    return _.get(this.form.data, this.fullKey, []).length + this.table.data.length
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
